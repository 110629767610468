// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.router-nav-fix {
  margin-top: -42px;
  margin-bottom: -87px;
  height: 100vh;
  top: 0;
  z-index: 1000;
}
.router-nav-fix.coms-spacer {
  margin-top: -24px;
  height: calc(100vh - 60px - 1px);
  top: 60px;
}

.outlet-container::ng-deep > :nth-child(2) {
  width: 100%;
}

::ng-deep .child-route-header {
  margin: 1.5rem 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
